@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.error {
  color: red;
}


.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eee;
}

.form-container {
  max-width: 450px;
  border-radius: 10px;
  background-color: white;

  margin-top: 100px!important;
  padding: 20px;
  
}
.form-container img {
  padding: 20px;
  width: 50%;
}