/* input, textarea, .dropdown {
  color: black !important;
  border-color: rgb(61 95 125)!important;
} */

.signature-canvas {
  border-radius: 5px;
  border: #ccc 1px solid;
}
.field-column {
  color: black;
  text-align: left;
  padding-left: 10px;
  background-color: #eee;
}
.field-column-disabled {
  color: black;
  text-align: left;
  padding-left: 10px;
  background-color: #eee;
  border: 1px solid #ccc;
}

.title-disabled {
  padding: 3px;
  background-color: #ccc;
  border-left: 10px solid black;
  margin-left: -10px;
}


.disabledWysiwygFormat {
  font-size: 1.1em; 
}

@media (max-width: 767px) {
  .field-column {
    text-align: left;
  }
}
