.markdown table, 
.markdown th, 
.markdown td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 5px;
    margin: 5px;;
  } 

  .markdown th {
    background-color: #eee;
  }

  .iso-item:hover {
    background-color: aliceblue!important;
  }