.custom-blue-icon {
    color: #007BFF!important;
  }

  .ag-theme-balham .ag-cell-bordered {
    border-right: 1px solid #eee;
  }
  .ag-theme-balham .ag-cell-readonly {
    background-color: #E0E0E0;
    border-bottom: 1px solid #eee;
  }


  .ag-theme-balham {
    --ag-header-height: 30px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #4e8ff1;
}