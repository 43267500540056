.clickable {
    cursor: pointer;
}
.markdown table, 
.markdown th, 
.markdown td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 5px;
    margin: 5px;;
  } 

  .markdown th {
    background-color: #eee;
  }


  .folder-icon {
    filter: drop-shadow(0 0 0.2rem rgb(255, 217, 0));
  }
  

