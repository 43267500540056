.round-label {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: #cccccc;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.1rem;
}
